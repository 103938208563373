/* ==========================================================================
   #SLIDER
   ========================================================================== */

$proportion-hd: (9/16)*1;

.c-slider {
  position: relative;

  &--hero {
    overflow: hidden;
  }

  &--fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 110;
    background-color: $color-white;
    display: flex;
    transition: opacity 0.4s, visibility 0.4s;
    opacity: 0;
    visibility: hidden;
    will-change: opacity;

    &.active {
      opacity: 1;
      visibility: visible;
    }
  }

  &--captions {
    border-top: 1px solid $color-light-grey;
    border-bottom: 1px solid $color-light-grey;
  }

  &__slides {
    position: relative;
    padding-top: $proportion-hd*100%;
    overflow: hidden;
  }

  &--captions &__slides {
    padding-top: calc((100% - 120px) * #{$proportion-hd} + 96px);
    max-height: 652px;
  }

  &--fullscreen &__slides {
    padding-top: 0;
    width: 100%;
    margin: 0 auto;
    max-width: 1600px;
  }

  &--hero &__slides {
    max-height: calc(100vh - 50px);
    padding-top: 0;
    height: $proportion-hd*100vw;
    min-height: 210px;
  }

  &__slide {
    object-fit: cover;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: transform 1s;
    transform: none;
    z-index: 2;
    user-select: none;
    margin: 0;
    will-change: transform;

    &--padding {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 40px 60px;
    }

    &.no-transition {
      transition: none;
    }

    &.right {
      transform: translateX(100%);
      z-index: 1;
    }

    &.left {
      transform: translateX(-100%);
      z-index: 1;
    }

    &.c-video {
      background-color: #000;
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      height: 100%;
      transform: translateY(-50%);
    }
  }

  &--fullscreen &__slide {
    width: 100%;
    height: auto;
  }

  &__slide--padding &__slide-inner {
    width: 100%;
    flex-grow: 1;
    flex-shrink: 1;
    display: block;
    position: relative;
    flex-basis: calc(#{$proportion-hd*100}vw - #{$proportion-hd*120}px);
  }

  &--hero &__slide-image,
  &__slide--padding &__slide-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
  }

  &__link {
    z-index: 4;
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: rgba(#fff, 0.85);
    line-height: (37/26);
    padding: 0.8em;
    text-align: center;
    display: inline-block;
    margin: 0 auto;
    right: auto;
    //width: 400px;
    max-width: calc(100% - 80px);
    transition: transform 1s;
    transform: translate(-50%, -50%);

    &.empty {
      display: none;
    }

    &-text {
      font-size: inherit;
      margin: -0.14em 0 -0.18em;
    }

    a {
      @include link-underline-responsive($color-yellow);
    }

    &.no-transition {
      transition: none;
    }

    &.right {
      transform: translate(calc(100vw - 50%), -50%);
      z-index: 1;
    }

    &.left {
      transform: translate(calc(-100vw - 50%), -50%);
      z-index: 1;
    }
  }

  &__caption {
    font-size: 10px;
    line-height: 1.6;
  }

  &__slide--padding &__caption {
    width: 100%;
    flex-shrink: 0;
    flex-grow: 0;
    margin-bottom: -20px;
    margin-top: 20px;
  }

  &__slide--video {
    &.right {
      transform: translate(100%, -50%);
    }

    &.left {
      transform: translate(-100%, -50%);
    }
  }

  &__arrow {
    position: absolute;
    top: 0;
    height: 100%;
    z-index: 3;
    padding: 20px;
    opacity: 0.5;
    transition: opacity 0.4s;
    width: 30%;
    text-align: left;
    outline: none;

    &:hover {
      opacity: 1;
    }

    &-image {
      fill: #fff;
      width: 15px;
      height: 27px;
      display: block;
      margin: auto auto auto 0;
    }

    &--previous {
      left: 0;
    }

    &--next {
      right: 0;
      text-align: right;
    }

    &--next &-image {
      transform-origin: 50% 50%;
      transform: rotate(180deg);
      margin: auto 0 auto auto;
    }
  }

  &__fullscreen {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    height: 100%;
    width: 40%;
    z-index: 3;
    outline: none;
  }

  &.is-video &__fullscreen,
  &.is-video &__arrow {
    height: calc(100% - 40px);
  }

  &--red &__arrow-image {
    fill: $color-pink;
  }

  &--grey &__arrow-image {
    fill: $color-light-grey;
  }

  &--big &__arrow-image {
    width: 27px;
    height: 46px;
  }

  &--fullscreen &__arrow {
    max-width: 480px;
  }

  &__close {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 25px;
    width: 40px;
    height: 40px;
    z-index: 3;

    &:hover {
      color: $color-pink;
    }
  }

  @include bp-max-medium {
    @at-root .o-container &:not(&--fullscreen) {
      margin-left: -40px;
      margin-right: -40px;
    }
  }

  @include bp-medium {
    &--captions &__slides {
      padding-top: calc((100% - 120px) * #{$proportion-hd} + 104px);
    }

    &--fullscreen &__slide--padding &__slide-inner,
    &--fullscreen &__slide--padding &__caption {
      max-width: 1160px;
    }

    &__caption {
      font-size: 15px;
    }

    &__slide--padding &__slide-inner {
      max-height: calc(#{$proportion-hd*100}vw - #{$proportion-hd*120}px);
    }

    &__link {
      text-align: left;
      transform: none;
      left: 60px;
      top: 80px;
      max-width: 400px;

      &.left {
        transform: translateX(-100vw);
      }

      &.right {
        transform: translateX(100vw);
      }
    }
  }

  @include bp-large {
    &--captions &__slides {
      padding-top: calc((100% - 120px) * #{$proportion-hd} + 112px);
    }

    &__caption {
      font-size: 20px;
    }

    &__link {
      top: 120px;
      left: 80px;
    }

    &--hero &__slides {
      max-height: calc(100vh - 80px);
    }
  }

  @include bp-xlarge {
    &--fullscreen &__slides {
      margin-top: 60px;
      margin-bottom: 60px;
      width: calc(100vw - 160px);
      border-top: 1px solid $color-light-grey;
      border-bottom: 1px solid $color-light-grey;
    }

    &--hero &__slides {
      max-height: calc(100vh - 100px);
    }

    &--captions &__slides {
      padding-top: calc((100% - 200px) * #{$proportion-hd} + 200px);
    }

    &--fullscreen &__arrow--previous {
      left: 55px;
    }

    &--fullscreen &__arrow--next {
      right: 55px;
    }

    &__slide--padding {
      padding: 60px 100px;
    }

    &__caption {
      margin-top: 80px;
      font-size: 25px;
    }

    &__slide--padding &__caption {
      margin-bottom: 0;
      margin-top: 40px;
    }

    &__close {
      right: 70px;
      top: 70px;
    }

    &__link {
      font-size: 35px;
      line-height: (46/35);
    }
  }

  @include bp-custom($bp-container-full) {
    &--fullscreen &__arrow--previous {
      left: calc(50vw - #{800 + 25}px);
    }

    &--fullscreen &__arrow--next {
      right: calc(50vw - #{800 + 25}px);
    }

    &--fullscreen &__close {
      right: calc(50vw - #{800 + 10}px);
      top: 120px;
    }

    &--fullscreen &__slides {
      margin-top: 100px;
      margin-bottom: 100px;
    }

    &--fullscreen &__slide--padding {
      padding: 80px 0;
    }

    &__link {
      left: calc(50% - 800px);
    }
  }

  @include bp-xlarge {
    @media (min-height: (652 + 80 + 41 + 160 + 100)+px) {
      &--fullscreen &__slides {
        margin-top: auto;
        margin-bottom: auto;
        height: (652 + 80 + 41 + 160)+px;
      }

      &--fullscreen &__close {
        top: calc(50vh - 431px);
      }
    }
  }

  @include bp-full {
    &__link {
      top: 180px;
      font-size: 50px;
      line-height: (60/50);
    }
  }

  @include bp-custom($bp-container-xfull) {
    &--fullscreen &__slides {
      max-width: 1920px;
    }

    &--fullscreen &__arrow {
      max-width: 576px;
    }

    &__link {
      left: calc(50% - 960px);
    }
  }
}
