/* ==========================================================================
   #ALIGN
   ========================================================================== */

.u-float-left {
  float: left;
}

.u-float-right {
  float: right;
}

.u-clear {
  clear: both;
}
