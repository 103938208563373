/* ==========================================================================
   #HEADLINE
   ========================================================================== */

.c-headline {
  &--parallax {
    position: relative;
    overflow: hidden;
  }

  &--parallax#{&}--background {
    display: flex;
    align-items: center;

    &:after {
      display: block;
      content: '';
      width: 0;
      height: 174px;
    }
  }

  &--parallax &__container {
    margin-top: auto;
    margin-bottom: auto;
  }

  &--pink {
    &:after {
      display: block;
      content: '';
      background-color: rgba($color-pink, 0.85);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &--parallax &__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 200%;
    transform: translateY(-25%);
    will-change: transform;
    object-fit: cover;
  }

  &--grey {
    background-color: $color-grey;
  }

  &__text {
    line-height: 1;
    margin: -0.16em 0;
    //padding: 24px 0;

    a {
      @include link-underline-responsive($color-yellow);
    }
  }

  &--pink &__text,
  &--background &__text,
  &--parallax &__text {
    margin: 0;
  }

  &--pink &__text {
    font-size: 20px;
    line-height: (25/20);
    color: #fff;
    margin-left: calc(50% + 2.5px);
    padding: 24px 40px 24px 0;
  }

  &--background &__text {
    background-color: rgba(#fff, 0.85);
    font-size: 26px;
    line-height: (37/26);
    padding: 15px 20px;
  }

  &--grey &__text {
    padding-top: 15px;
    padding-bottom: 10px;
  }

  &--pink &__container {
    position: relative;
    z-index: 1;
    max-width: none;
    margin: 0;
    width: auto;
  }

  &--background &__container {
    position: relative;
    z-index: 1;
    text-align: center;
    padding: 35px 0;
    display: flex;
    justify-content: center;
  }

  @include bp-medium {
    &__text {
      //padding: 22px 0;
    }

    &--pink &__text {
      font-size: 25px;
      line-height: (35/25);
      margin-left: calc(62.5% + 2.5px);
      padding: 24px 0;
    }

    &--background &__text {
      text-align: left;
    }

    &--background &__container {
      justify-content: flex-end;
    }
  }

  @include bp-large {
    &__text {
      //padding: 30px 0;
    }

    &--pink &__text {
      padding: 40px 80px 40px 0;
      margin-left: calc(66.666% + 3.5px);
    }

    &--grey &__text {
      padding-top: 25px;
      padding-bottom: 15px;
    }

    &--background &__container {
      padding: 60px 0;
    }

    &--parallax#{&}--background:after {
      height: 224px;
    }
  }

  @include bp-xlarge {
    &--pink &__text {
      font-size: 35px;
      line-height: (45/35);
      padding: 60px 80px 60px 0;
    }

    &--background &__text {
      font-size: 35px;
      line-height: (46/35);
      padding: 20px 30px;
    }

    &--grey &__text {
      padding-top: 35px;
      padding-bottom: 14px;
    }

    &--background &__container {
      padding: 75px 0;
    }

    &--parallax#{&}--background:after {
      height: 282px;
    }
  }

  @include bp-full {
    &__text {
      //padding: 50px 0;
    }

    &--pink &__text {
      font-size: 50px;
      line-height: (70/50);
      padding: 72px 0;
    }

    &--background &__text {
      font-size: 50px;
      line-height: (60/50);
      padding: 22px 30px;
    }

    &--background &__container {
      padding: 120px 0;
    }

    &--parallax#{&}--background:after {
      height: 404px;
    }
  }
}
