/* ==========================================================================
   #LINK
   ========================================================================== */

.o-link {
  transition: color 0.3s;

  &--pink {
    &:hover {
      color: $color-pink;
    }
  }
}
