/* ==========================================================================
   #HAMBURGER
   ========================================================================== */

.o-hamburger {
  text-align: center;
  width: 40px;
  height: 40px;
  margin: -10px -12px -10px 0;

  &__image {
    display: block;
    fill: #fff;
    width: 16px;
    height: 11px;
    margin: 0 auto;
  }

  @at-root .no-touch &:hover &__image {
    fill: $color-yellow;
  }

  @include bp-large {
    margin: -4px -12px -10px 0;

    &__image {
      width: 20px;
      height: 14px;
    }
  }

  @include bp-full {
    margin: 0 -6px 0 0;

    &__image {
      width: 28px;
      height: 20px;
    }
  }

  @include bp-xfull {
    margin-right: -3px;

    &__image {
      width: 34px;
      height: 24px;
    }
  }
}
