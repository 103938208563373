/* ==========================================================================
   #HIDE
   ========================================================================== */

.u-hide-on {
  &-small {
    @include bp-max-medium {
      display: none !important;
    }
  }

  &-medium {
    @include bp-max-large {
      @include bp-medium {
        display: none !important;
      }
    }
  }

  &-large {
    @include bp-max-xlarge {
      @include bp-large {
        display: none !important;
      }
    }
  }

  &-xlarge {
    @include bp-max-full {
      @include bp-xlarge {
        display: none !important;
      }
    }
  }

  &-full {
    @include bp-full {
      display: none !important;
    }
  }
}

.u-show-on {
  &-small {
    @include bp-medium {
      display: none !important;
    }
  }
}
