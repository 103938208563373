/* ==========================================================================
   #PAGE
   ========================================================================== */

html {
  background: $color-bg;
  color: $color-text;
  font-family: $font-sans-serif;
  font-size: 15px;
  line-height: (25/15);
  min-height: 100%;
  overflow-y: scroll;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

