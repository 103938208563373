/* ==========================================================================
   #PEOPLE
   ========================================================================== */

.c-people {
  color: #fff;

  &__header {
    background-color: $color-grey;
  }

  &__title {
    font-size: 10px;
    padding: 12px 0 8px;
  }

  &__items {
    margin-left: -3px;
    display: flex;
    flex-wrap: wrap;
  }

  &__item {
    margin-top: 3px;
    margin-left: 3px;
    display: block;
    width: calc(49.99% - 3px);
    position: relative;
  }

  &__image {
    display: block;
    width: 100%;
    height: auto;
  }

  &__caption {
    font-size: 10px;
    background-color: rgba(#000, 0.95);
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    padding: 5px 10px;
    transition: opacity 0.3s;
    opacity: 0;
  }

  &__item:hover &__caption {
    opacity: 1;
  }

  @include bp-custom(480px) {
    &__item {
      width: calc(33.3333% - 3px);
    }
  }

  @include bp-medium {
    display: flex;
    align-items: stretch;

    &__header {
      width: (104/768)*100%;
      flex-shrink: 0;
      flex-grow: 0;
    }

    &__title {
      margin: 0;
      text-align: right;
      width: 100%;
      font-size: 8px;
      padding-top: 10px;
      padding-right: 23px;
      line-height: (24/20);
    }

    &__items {
      margin-left: 0;
      margin-top: -3px;
    }

    &__item {
      width: calc(19.99% - 3px);
    }

    &__caption {
      font-size: 8px;
      padding-left: 1em;
    }
  }

  @include bp-large {
    &__header {
      width: (138/1024)*100%;
    }

    &__title {
      font-size: 10px;
      padding-top: 13px;
      padding-right: 18px;
    }

    &__caption {
      font-size: 10px;
    }

    &__items {
      margin-top: -4px;
    }

    &__item {
      width: calc(19.99% - 4px);
      margin-left: 4px;
      margin-top: 4px;
    }
  }

  @include bp-xlarge {
    &__header {
      width: (172/1280)*100%;
    }

    &__title {
      font-size: 15px;
      padding-top: 17px;
      padding-right: 33px;
    }

    &__caption {
      font-size: 12px;
      padding: 7px 12px 7px 18px;
    }

    &__items {
      margin-top: -5px;
    }

    &__item {
      width: calc(19.99% - 5px);
      margin-left: 5px;
      margin-top: 5px;
    }
  }

  @include bp-full {
    &__header {
      width: (258/1920)*100%;
    }

    &__title {
      font-size: 20px;
      padding-top: 25px;
      padding-right: 50px;
      padding-left: 20px;
    }

    &__caption {
      font-size: 15px;
      padding: 10px 15px 10px 25px;
    }

    &__items {
      margin-top: -7px;
    }

    &__item {
      width: calc(19.99% - 7px);
      margin-left: 7px;
      margin-top: 7px;
    }
  }
}
