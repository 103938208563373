/* ==========================================================================
   #HEADINGS
   ========================================================================== */

/**
 * Simple default styles for headings 1 through 6. Anything more opinionated
 * than simple font-size changes should likely be applied via classes (see:
 * http://csswizardry.com/2016/02/managing-typography-on-large-apps/).
 */

h1 {
  font-size: inherit;
}

h2 {
  font-size: inherit;
}

h3 {
  font-size: inherit;
}

h4 {
  font-size: inherit;
}

h5 {
  font-size: inherit;
}

h6 {
  font-size: inherit;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: inherit;
  margin-bottom: 1em;

  &:last-child {
    margin-bottom: 0;
  }
}
