/* ==========================================================================
   #FOOTER
   ========================================================================== */

.c-footer {
  background-color: $color-yellow;
  color: $color-grey;
  text-align: center;
  padding: 15px 0;

  a:hover {
    color: #000;
  }

  &__info {
    font-style: normal;
    font-size: 10px;
    line-height: 1.5;
    margin-bottom: 15px;
  }

  &__address {
    font-style: normal;
    margin-bottom: 0;
  }

  &__copyright {
    margin-bottom: 15px;
  }

  &__navigation {
    list-style-type: none;
    margin-left: 0;
    margin-bottom: 15px;

    li {
      margin: 0;
      display: inline;

      &:before {
        content: '|';
        padding: 0 8px;
      }
    }
  }

  &__social {
    &-links {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 0;
      width: 100%;
    }

    &-link {
      display: block;
      margin: 0 10px;
    }

    &-icon {
      display: block;
      height: 15px;

      &--twitter {
        width: 18px;
      }

      &--linkedin {
        width: 16px;
      }

      &--instagram {
        width: 16px;
      }

      &--vimeo {
        width: 19px;
      }
    }
  }

  &__login-links {
    width: 100%;
    list-style-type: none;
    margin-left: 0;
    margin-bottom: 15px;
    font-size: 15px;
    line-height: (20/15);
    font-weight: 700;

    &:not(.active) {
      display: none;
    }

    li {
      margin-bottom: 0;
      display: inline;

      &:before {
        content: '|';
        padding: 0 8px;
      }

      &:first-child:before {
        display: none;
      }
    }
  }

  @include bp-max-medium {
    &__navigation li:first-child:before {
      display: none;
    }
  }

  @include bp-medium {
    padding: 10px 0;

    &__container {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: flex-start;
    }

    &__info {
      display: inline;
      margin-bottom: 10px;

      address {
        display: inline;
      }

      br {
        display: none;
      }
    }

    &__copyright {
      display: inline;

      &:before {
        content: '|';
        padding: 0 8px;
      }
    }

    &__navigation {
      margin-bottom: 0;
      display: inline;
    }

    &__login-links,
    &__info {
      margin-bottom: 10px;
    }
  }

  @include bp-large {
    text-align: left;

    &__container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
    }

    &__login-links {
      order: 1;
      margin-bottom: 0;
      margin-top: 10px;
    }

    &__info {
      margin-bottom: 0;
    }

    &__social-links {
      width: auto;
      margin-bottom: 0;
    }
  }

  @include bp-xlarge {
    padding: 20px 0;

    &__info {
      font-size: 15px;
    }

    &__login-links {
      font-size: 20px;
    }

    &__social-link {
      margin: 0 15px;
    }

    $scale: (20/15);

    &__social-icon {
      display: block;
      height: 15px * $scale;

      &--twitter {
        width: 18px * $scale;
      }

      &--linkedin {
        width: 16px * $scale;
      }

      &--instagram {
        width: 16px * $scale;
      }

      &--vimeo {
        width: 19px * $scale;
      }
    }
  }
}
