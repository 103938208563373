/* ==========================================================================
   #TESTIMONIAL
   ========================================================================== */

.c-testimonial {
  color: #fff;
  background-color: $color-grey;
  padding: (50/25)+em (70/25)+em;

  &__quote {
    line-height: (40/25);
    margin-bottom: 0;

    &-text {
      position: relative;
      margin-bottom: 0;

      &:before {
        content: '”';
        color: $color-yellow;
        font-size: 2em;
        font-family: $font-serif;
        transform: scaleX(-1);
        position: absolute;
        top: -0.1em;
        right: 100%;
        margin-right: (8/50)+em;
        line-height: 1;
      }
    }

    &-text p:last-child {
      margin-bottom: 0;

      &:after {
        content: '”';
      }
    }
  }

  &__cite {
    display: block;
    margin-top: 1.6em;
  }
}
