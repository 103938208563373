/* ==========================================================================
   #SEARCH
   ========================================================================== */

.c-search {
  font-size: 10px;
  max-width: 940px;

  &__title {
    font-size: inherit;
    margin-bottom: 5px;
    line-height: 1;
  }

  &__result {
    & + & {
      margin-top: 5px;
    }

    &-text {
      font-size: 8px;
      line-height: (12/8);
    }
  }

  &__row {
    & + & {
      margin-top: 18px;
    }
  }

  @include bp-medium {
    padding-right: 40px;
  }

  @include bp-large {
    font-size: 15px;
    padding-right: 50px;

    &__result {
      &-text {
        font-size: 10px;
      }
    }

    &__row {
      & + & {
        margin-top: 22px;
      }
    }
  }

  @include bp-xlarge {
    font-size: 20px;
    padding-right: 60px;

    &__result {
      &-text {
        font-size: 15px;
      }
    }

    &__row {
      & + & {
        margin-top: 30px;
      }
    }
  }
  @include bp-xlarge {
    font-size: 25px;

    &__title {
      font-size: 30px;
      margin-bottom: 10px;
    }

    &__result {
      & + & {
        margin-top: 10px;
      }

      &-text {
        font-size: 20px;
      }
    }

    &__row {
      & + & {
        margin-top: 55px;
      }
    }
  }
}
