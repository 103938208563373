/* ==========================================================================
   #SAY HELLO
   ========================================================================== */

.c-say-hello {
  font-size: 14px;
  margin-left: -10px;
}

.c-say-hello__row {
  display: flex;
  align-items: flex-end;
  margin-bottom: 2em;
  padding: 0;
}

.c-say-hello__label {
  width: 120px;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 5px;
  line-height: (28/14) + em;

  &--top {
    align-self: flex-start;
  }
}

.c-say-hello__row > :last-child {
  flex-grow: 1;
  flex-shrink: 0;
  width: calc(100% - 120px);
}

.c-say-hello__row--message textarea {
  margin-top: 28px;
}

.c-say-hello__row input {
  border-bottom: 1px solid black;
  line-height: 1.5;
  padding: 3px 0;
  outline: none;
}

.c-say-hello__row textarea {
  border: 1px solid black;
  line-height: 1.5;
  padding: 3px 6px;
  outline: none;
}

.c-say-hello__row input:-webkit-autofill {
  &, &:hover, &:focus {
    -webkit-box-shadow: 0 0 0 100px #ffffff inset !important;
    border-bottom: 1px solid black;
  }
}

.c-say-hello.is-sent .c-say-hello__form,
.c-say-hello:not(.is-sent) .c-say-hello__message {
  display: none;
}

.c-say-hello__error-message {
  margin-top: 20px;
}

.c-say-hello-recaptcha {
  display: block;
  width: 100%;
}
