/* ==========================================================================
   #COMMENT
   ========================================================================== */


.c-comment {
  font-family: $font-sans-serif;
  margin: 0;
  font-size: 12px;
  line-height: 1.4;

  > p + cite {
    margin-top: -1em;
  }

  cite {
    display: block;
  }

  & + & {
    margin-top: 2em;
  }

  @include bp-xlarge {
    font-size: 20px;
  }

  @include bp-xlarge {
    cite {
      font-size: 15px;
    }
  }
}
