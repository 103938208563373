/* ==========================================================================
   #SEARCH FORM
   ========================================================================== */

.c-search-form {
  display: flex;
  align-items: flex-end;

  &__submit {
    width: 25px;
    height: 25px;
    flex-grow: 0;
    flex-shrink: 0;
    margin-right: 15px;

    &-image {
      display: block;
      width: 25px;
      height: 25px;
      fill: #fff;
    }
  }

  &--small &__submit {
    width: 19px;
    height: 19px;
    margin-right: 10px;

    &-image {
      width: 19px;
      height: 19px;
    }
  }

  @at-root .no-touch &__submit:hover &__submit-image {
    fill: $color-yellow;
  }

  &__input {
    border-bottom: 4px solid $color-yellow;
    font-size: 25px;
    padding: 0 4px;
    flex-grow: 1;
    flex-shrink: 1;
    width: calc(100% - 40px);
  }

  &--small &__input {
    font-size: 15px;
    border-width: 3px;
  }

  @include bp-medium {
    &--small &__submit {
      width: 14px;
      height: 14px;
      margin-bottom: 2px;

      &-image {
        width: 14px;
        height: 14px;
      }
    }
  }

  @include bp-xlarge {
    &--small &__submit {
      width: 20px;
      height: 20px;
      margin-bottom: 2px;
      margin-right: 13px;

      &-image {
        width: 20px;
        height: 20px;
      }
    }

    &--small &__input {
      font-size: 20px;
      border-width: 4px;
    }
  }

  @include bp-full {
    &--small &__submit {
      width: 23px;
      height: 23px;
      margin-bottom: 2px;
      margin-right: 19px;

      &-image {
        width: 23px;
        height: 23px;
      }
    }

    &--small &__input {
      font-size: 25px;
      border-width: 5px;
    }
  }
}
