/* ==========================================================================
   #COLUMNS
   ========================================================================== */

.o-columns {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  &__column {
    width: 100%;
    position: relative;
  }

  @include bp-max-medium {
    &--reverse {
      flex-direction: column-reverse;
    }
  }

  @include bp-medium {
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;

    &--middle {
      align-items: center;
    }

    &__column {
      &--wide {
        width: (406/(768 - 120))*100%;
      }

      &--half {
        width: calc(50% - 21px);
      }

      &--quarter {
        width: calc(25% - 28px);
      }

      &--narrow {
        width: (200/(768 - 120))*100%;
      }
    }
  }

  @include bp-large {
    &__column {
      &--wide {
        width: (562/(1024 - 160))*100%;
      }

      &--narrow {
        width: (260/(1024 - 160))*100%;
      }
    }
  }

  @include bp-xlarge {
    &__column {
      &--wide {
        width: (730/(1280 - 160))*100%;
      }

      &--half {
        width: calc(50% - 25px);
      }

      &--quarter {
        width: calc(25% - 33.333px);
      }

      &--narrow {
        width: (340/(1280 - 160))*100%;
      }
    }
  }

  @include bp-full {
    &__column {
      &--wide {
        width: (1065/1600)*100%;
      }

      &--half {
        width: calc(50% - 30px);
      }

      &--quarter {
        width: calc(25% - 40px);
      }

      &--narrow {
        width: (475/1600)*100%;
      }
    }
  }
}
