.o-text {
  font-size: 12px;
  line-height: (16/12);
  font-family: $font-serif;

  &-size-normal {
    font-size: 12px;
  }

  &--sans-serif {
    font-family: $font-sans-serif;
  }

  &--title {
    font-size: 15px;
    line-height: (20/15);
  }

  &--small {
    font-size: 10px;
  }

  &--no-last-margin {
    & > *:last-child {
      margin-bottom: 0;
    }
  }

  blockquote {
    @extend .c-blockquote;
  }

  @include bp-large {
    font-size: 15px;
    line-height: (20/15);

    &-size-normal {
      font-size: 15px;
    }

    &--small {
      font-size: 10px;
    }
  }

  @include bp-xlarge {
    font-size: 20px;
    line-height: (25/20);

    &--small {
      font-size: 15px;
      //line-height: (18/15);
    }

    &-size-normal {
      font-size: 20px;
    }
  }

  @include bp-full {
    font-size: 25px;
    line-height: (35/25);

    &--small {
      font-size: 20px;
      //line-height: (25/20);
    }

    &-size-normal {
      font-size: 25px;
    }
  }
}
