/* ==========================================================================
   #BLOCKQUOTE
   ========================================================================== */

.c-blockquote {
  quotes: '“' '”' '‘' '’';
  font-style: normal;
  font-size: 15px;
  line-height: 1.2;
  margin: 10px 0;
  font-family: $font-serif;

  p:first-child {
    position: relative;

    &:before {
      content: open-quote;
      color: $color-yellow;
      position: absolute;
      right: 100%;
      top: 0;
    }
  }

  p:last-of-type {
    margin-bottom: 0;

    &:after {
      content: close-quote;
    }
  }

  cite {
    font-style: normal;
    font-family: $font-sans-serif;
    font-size: 8px;
    margin-top: 5px;
  }

  @include bp-medium {
    margin: 12px 0;
    font-size: 16px;

    cite {
      font-size: 10px;
    }
  }

  @include bp-large {
    margin: 14px 0;
    font-size: 24px;

    cite {
      font-size: 12px;
    }
  }

  @include bp-xlarge {
    margin: 16px 0;
    font-size: 30px;

    cite {
      font-size: 15px;
    }
  }

  @include bp-full {
    margin: 20px 0;
    font-size: 40px;

    cite {
      font-size: 20px;
    }
  }
}
