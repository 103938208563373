/* ==========================================================================
   #LINKS LIST
   ========================================================================== */

.o-links-list {
  margin-bottom: 0;

  &__item {
    margin-bottom: 1em;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
