/* ==========================================================================
   #TILE
   ========================================================================== */

.c-tile {
  display: block;
  position: relative;
  color: $color-white;
  overflow: hidden;

  $tile-gap: tile-gap();
  $scale: 185/125;
  $tile-width: (320 - (1 * $tile-gap)) / 2;
  $tile-height: $tile-width / $scale;
  @include tile-sizes($tile-width, $tile-height, $tile-gap, 2);

  &--white {
    color: $color-black;
  }

  &__poster {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    user-select: none;
  }

  &--inline &__poster {
    position: static;
    width: auto;
    height: auto;
  }

  &__overlay {
    position: absolute;
    left: 0;
    bottom: 0;
    padding: (20 - $tile-gap) + px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: flex-start;
    align-content: flex-end;
    width: 100%;
    height: 100%;
    background-color: rgba($color-pink, 0.85);
    transition: opacity 0.3s, visibility 0.3s;
    opacity: 0;
    visibility: hidden;
  }

  &--white &__overlay {
    background-color: transparent;
    opacity: 1;
    visibility: visible;
  }

  &:not(&--video):hover &__overlay,
  &.active &__overlay {
    opacity: 1;
    visibility: visible;
  }

  &__video {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    @at-root .c-tile:not(.c-tile--inline) & .c-video__video-wrapper {
      @include tile-video-size($tile-width * 2 + $tile-gap, $tile-height * 2 + $tile-gap);
    }
  }

  & &__video {
    position: absolute;
  }

  &--inline#{&}--video {
    padding-top: (9/16)*100%;
  }

  &__headline {
    font-size: 15px;
    margin: 0;
    line-height: (20/15);
    width: 100%;
  }

  &__subheadline {
    font-size: 8px;
    border-bottom: 2px solid $color-yellow;
    max-width: 100%;
    min-width: 120px;
    margin-bottom: 1px;
    padding-bottom: 1px;
    line-height: 1;
  }

  &--white &__subheadline {
    border-color: $color-pink;
  }

  &__more {
    fill: $color-white;
    width: 40px;
    height: 40px;
    position: absolute;
    right: 15px;
    top: 10px;
    text-align: center;

    &-icon {
      display: block;
      width: 34px;
      height: 34px;
      margin: auto;
    }
  }

  &__caption {
    text-transform: uppercase;
    font-size: 10px;
    padding: 10px 15px; //padding: 12px 15px;
    position: absolute;
    left: 0;
    bottom: 0;
    transition: opacity 0.3s;
  }

  &:hover &__caption {
    opacity: 0;
  }

  &--black-caption &__caption {
    color: #000;
  }

  &--only-caption &__caption {
    background-color: rgba(#000, 0.95);
    opacity: 0;
    text-transform: none;
    padding: 15px 15px 13px;
    width: 100%;
  }

  &--only-caption:hover &__caption {
    opacity: 1;
  }

  @include bp-small() {
    &__subheadline {
      min-width: 150px;
    }
  }

  @include bp-medium {
    $tile-gap: tile-gap($bp-medium);
    $scale: 188/128;
    $tile-width: ($bp-medium - (3 * $tile-gap)) / 4;
    $tile-height: $tile-width / $scale;
    @include tile-sizes($tile-width, $tile-height, $tile-gap, 4);

    &__video {
      @at-root .c-tile:not(.c-tile--inline) & .c-video__video-wrapper {
        @include tile-video-size($tile-width * 2 + $tile-gap, $tile-height * 2 + $tile-gap);
      }
    }

    &__headline {
      font-size: 15px;
    }

    &__subheadline {
      font-size: 10px;
      padding-bottom: 2px;
      margin-bottom: 2px;
    }
  }

  @include bp-large {
    $tile-gap: tile-gap($bp-large);
    $scale: 164/110;
    $tile-width: ($bp-large - (5 * $tile-gap)) / 6;
    $tile-height: $tile-width / $scale;
    @include tile-sizes($tile-width, $tile-height, $tile-gap, 6);

    &__video {
      @at-root .c-tile:not(.c-tile--inline) & .c-video__video-wrapper {
        @include tile-video-size($tile-width * 2 + $tile-gap, $tile-height * 2 + $tile-gap);
      }
    }

    &__overlay {
      padding: (20 - $tile-gap) + px;
    }

    &__subheadline {
      font-size: 8px;
      padding-bottom: 1px;
      margin-bottom: 1px;
      min-width: 135px;
    }
  }

  @include bp-xlarge {
    $tile-gap: tile-gap($bp-xlarge);
    $scale: 207/140;
    $tile-width: ($bp-xlarge - (5 * $tile-gap)) / 6;
    $tile-height: $tile-width / $scale;
    @include tile-sizes($tile-width, $tile-height, $tile-gap, 6);

    &__video {
      @at-root .c-tile:not(.c-tile--inline) & .c-video__video-wrapper {
        @include tile-video-size($tile-width * 2 + $tile-gap, $tile-height * 2 + $tile-gap);
      }
    }

    &__overlay {
      padding: 15px 20px;
    }

    &__headline {
      font-size: 20px;
      line-height: (25/20);
    }

    &__subheadline {
      font-size: 10px;
      padding-bottom: 2px;
      margin-bottom: 2px;
      min-width: 170px;
      border-width: 3px;
    }

    &__caption {
      font-size: 15px;
      padding: 10px 20px; // padding: 15px 20px;
    }

    &--only-caption &__caption {
      padding: 20px 20px 18px;
    }
  }

  @include bp-custom($bp-container-full) {
    $tile-gap: tile-gap($bp-container-full);
    $scale: 313/213;
    $tile-width: ($bp-container-full - (5 * $tile-gap)) / 6;
    $tile-height: $tile-width / $scale;
    @include tile-sizes($tile-width, $tile-height, $tile-gap, 6);

    &__video {
      @at-root .c-tile:not(.c-tile--inline) & .c-video__video-wrapper {
        @include tile-video-size($tile-width * 2 + $tile-gap, $tile-height * 2 + $tile-gap);
      }
    }

    &__overlay {
      padding: 25px 30px;
    }

    &__headline {
      font-size: 30px;
      line-height: (35/30);
    }

    &__subheadline {
      border-width: 5px;
      font-size: 15px;
      min-width: 0;
    }

    &--1x1 &__subheadline,
    &--1x2 &__subheadline,
    &--1x3 &__subheadline {
      min-width: 170px;
      width: auto;
    }
  }
}

