/* ==========================================================================
   #POST META
   ========================================================================== */

.c-post-meta {
  display: flex;
  flex-direction: column;
  padding-left: 160px;
  min-height: 145px;
  position: relative;

  &--bio,
  &--multi {
    padding-left: 0;
    min-height: 0;
  }

  &--multi {
    flex-direction: row;
    flex-wrap: wrap;
  }

  &__author-image {
    display: block;
    position: absolute;
    top: 0;
    left: 0;

    img {
      display: block;
      width: 145px;
      height: 145px;
    }
  }

  &--bio &__author-image {
    position: static;
    margin-bottom: 15px;
  }

  &__date {
    font-size: 10px;
    margin-bottom: 8px;
    width: 100%;
  }

  &__tags {
    font-size: 10px;
    line-height: 1.2;
    margin-bottom: 10px;
    width: 100%;
  }

  &__bio {
    line-height: 1.2;
  }

  &__authors {
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    min-width: 100%;
  }

  &__author {
    font-size: 15px;
    line-height: (18/15);
  }

  &__author-wrapper {
    width: calc(50% - 10px);
    margin-bottom: 10px;
    margin-left: 10px;
    max-width: 145px;
  }

  &__share {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    line-height: 1;
    align-self: flex-start;
    justify-content: space-between;
    margin-top: auto;
    width: 100%;
    max-width: 126px;

    &-link {
      display: block;
      margin-top: 6px;

      &:hover {
        color: $color-pink;
      }
    }

    &-title {
      font-size: 15px;
      width: 100%;
    }

    &-icon {
      display: block;
      width: 18px;
      height: 15px;
    }
  }

  &--multi &__share {
    width: 100%;
  }

  @include bp-max-medium {
    &__bio {
      font-size: 14px;
    }
  }

  @include bp-max-medium {
    &--multi &__author {
      margin-top: 5px;
      font-size: 13px;
    }

    @include bp-custom(420px) {
      &--multi &__author {
        font-size: 15px;
      }
    }

    &--multi &__author-image {
      position: static;

      img {
        width: 100%;
        height: auto;
      }
    }
  }

  @include bp-medium {
    padding-left: 0;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;

    &__date {
      font-size: 8px;
    }

    &__authors {
      margin: 0;
      display: block;
    }

    &__author-image {
      margin-right: 11px;
      position: static;

      img {
        width: 105px;
        height: 105px;
      }
    }

    &__author-wrapper {
      display: flex;
      align-items: center;
      margin-left: 0;
      max-width: none;
      width: auto;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__share {
      max-width: none;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: flex-start;
      width: auto;
      margin-top: 20px;

      &-title {
        margin-right: 12px;
        font-size: 10px;
      }

      &-link {
        margin-top: 0;
        margin-right: 15px;
      }

      &-icon {
        width: 17px;
        height: 14px;
      }
    }

    &__tags {
      font-size: 8px;
    }

    &__author {
      width: calc(100% - 116px);
      font-size: 10px;
    }
  }

  @include bp-large {
    &__date, &__tags {
      font-size: 10px;
    }

    &__tags {
      margin-bottom: 12px;
    }

    &__bio {
      line-height: 1.4;
    }

    &__author-image {
      margin-right: 15px;

      img {
        width: 133px;
        height: 133px;
      }
    }

    &__author {
      width: calc(100% - 148px);
      font-size: 15px;
    }

    &__share {
      margin-top: 25px;

      &-title {
        font-size: 13px;
        margin-right: 13px;
      }

      &-link {
        margin-right: 18px;
      }

      &-icon {
        width: 18px;
        height: 18px;
      }

      &-icon--twitter {
        width: 22px;
      }

      &-icon--linkedin {
        width: 19px;
      }

      &-icon--mail {
        width: 23px;
        height: 14px;
      }
    }
  }

  @include bp-xlarge {
    &__date, &__tags {
      font-size: 15px;
    }

    &__date {
      margin-bottom: 15px;
    }

    &__tags {
      margin-bottom: 20px;
    }

    &__bio {
      font-size: 15px;
      line-height: 1.5;
    }

    &__author-image {
      margin-right: 20px;

      img {
        width: 175px;
        height: 175px;
      }
    }

    &--bio &__author-image {
      margin-bottom: 20px;
    }

    &__author {
      width: calc(100% - 195px);
      font-size: 20px;
    }

    &__share {
      margin-top: 35px;

      &-title {
        font-size: 15px;
        margin-right: 25px;
      }

      &-link {
        margin-right: 25px;
      }

      &-icon {
        width: 24px;
        height: 24px;
      }

      &-icon--twitter {
        width: 28px;
      }

      &-icon--linkedin {
        width: 26px;
      }

      &-icon--mail {
        width: 30px;
        height: 18px;
      }
    }
  }

  @include bp-full {
    &__date, &__tags {
      font-size: 20px;
    }

    &__date {
      margin-bottom: 15px;
    }

    &__tags {
      margin-bottom: 25px;
    }

    &__bio {
      font-size: 20px;
    }

    &__author-image {
      margin-right: 25px;

      img {
        width: 250px;
        height: 250px;
      }
    }

    &__author {
      width: calc(100% - 275px);
      font-size: 30px;
    }

    &__share {
      margin-top: 50px;

      &-title {
        font-size: 25px;
        margin-right: 25px;
      }

      &-link {
        margin-right: 30px;
      }

      &-icon {
        width: 34px;
        height: 34px;
      }

      &-icon--twitter {
        width: 41px;
      }

      &-icon--linkedin {
        width: 36px;
      }

      &-icon--mail {
        width: 44px;
        height: 27px;
      }
    }
  }
}
