/* ==========================================================================
   #CONTENT WRAPPER
   ========================================================================== */

.c-content-wrapper {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  min-height: 100vh;

  .c-header,
  .c-main {
    align-self: flex-start;
    margin-top: 0;
  }

  .c-footer {
    align-self: flex-end;
  }

  .c-main {
    margin-bottom: auto;
  }

  & > * {
    width: 100%;
  }
}
