/* ==========================================================================
   #TAG CLOUD
   ========================================================================== */

.o-tag-cloud {
  font-size: 10px;
  line-height: 1.8;

  a {
    margin-right: 5px;
    @include link-underline-responsive($color-yellow, $underline-thickness-small, $underline-thickness-medium, $underline-thickness-large, false, 0.9em); // on request changed from ($color-yellow, 3px, 2px, 4px, 5px);
    border-bottom: 2px solid transparent;
  }

  @include bp-medium {
    font-size: 8px;
    line-height: 13px;
  }

  @include bp-large {
    font-size: 10px;
    line-height: 16px;

    a {
      border-bottom: 3px solid transparent;
    }
  }

  @include bp-xlarge {
    font-size: 15px;
    line-height: 21px;

    a {
      margin-right: 10px;
    }
  }

  @include bp-full {
    font-size: 20px;
    line-height: 30px;
  }
}
