/* ==========================================================================
   #COLOR
   ========================================================================== */

.u-color {
  &-yellow {
    color: $color-yellow;
  }

  &-pink {
    color: $color-pink;
  }

  &-grey {
    color: $color-grey;
  }

  &-light-grey {
    color: $color-light-grey;
  }

  &-white {
    color: #fff;
  }
}
