/* ==========================================================================
   #NAVIGATION
   ========================================================================== */

.c-navigation {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
  transition: background-color 0.4s, visibility 0.4s;
  visibility: hidden;
  pointer-events: none;

  * {
    pointer-events: none;
  }

  & > *:last-child {
    margin-bottom: 0;
  }

  @at-root {
    .is-menu-open & {
      background-color: rgba(#fff, 0.85);
      visibility: visible;
      pointer-events: auto;
      -webkit-overflow-scrolling: touch;

      * {
        pointer-events: auto;
      }
    }
  }

  &__container {
    background-color: $color-grey;
    color: #fff;
    width: 375px;
    max-width: 100%;
    margin-left: auto;
    margin-right: 0;
    min-height: 100vh;
    padding: 50px 40px;
    transition: transform 0.4s;
    transform: translateX(100%);
    position: relative;

    a {
      @include link-underline($color-yellow, 7px);
      border-bottom: 4px solid transparent;
    }

    @at-root {
      .is-menu-open & {
        transform: none;
      }
    }
  }

  &__close {
    font-size: 25px;
    width: 40px;
    height: 40px;
    text-align: center;
    position: absolute;
    right: 10px;
    top: 44px;

    &:hover {
      color: $color-yellow;
    }
  }

  &__news {
    font-size: 15px;

    &-title {
      font-size: 25px;
      margin-bottom: 25px;
    }

    &-item {
      padding-left: 20px;
      padding-bottom: 15px;
      margin-bottom: 25px;
      border-bottom: 1px solid #fff;
    }

    p {
      margin-bottom: 0;
    }

    &-items {
      list-style-type: none;
      margin: 0;
    }

    &-author {
      margin-top: 25px;
    }
  }

  &__search {
    margin-bottom: 50px;

    .c-search-form__input {
      transition: opacity 0.3s, visibility 0.3s;
      opacity: 0;
      visibility: hidden;
    }

    &.active .c-search-form__input,
    &:hover .c-search-form__input {
      opacity: 1;
      visibility: visible;
    }
  }

  @include bp-medium {
    &__container {
      padding: 50px 25px;
    }

    &__search {
      margin-bottom: 150px;
    }

    &__news {
      &-author {
        margin-top: 11px;
      }
    }
  }
}
