/* ==========================================================================
   #SELECT
   ========================================================================== */

.o-select {
  border: 1px solid $color-light-grey;
  position: relative;
  font-size: 10px;

  &__input {
    display: block;
    width: 100%;
    border: none;
    appearance: none;
    line-height: 1.2;
    padding: 6px 45px 6px 8px;

    &::-ms-expand {
      display: none;
    }

    option {
      font: inherit;
    }
  }

  &__icon {
    width: 16px;
    height: 8px;
    position: absolute;
    right: 27px;
    top: 50%;
    margin-top: -4px;
  }

  @include bp-medium {
    font-size: 8px;

    &__input {
      padding-right: 30px;
    }

    &__icon {
      width: 11px;
      height: 6px;
      margin-top: -3px;
      right: 19px;
    }
  }

  @include bp-large {
    font-size: 10px;

    &__input {
      padding-right: 37px;
    }

    &__icon {
      width: 14px;
      height: 7px;
      right: 23px;
    }
  }

  @include bp-xlarge {
    font-size: 15px;

    &__input {
      padding-right: 47px;
    }

    &__icon {
      width: 18px;
      height: 9px;
      margin-top: -4px;
      right: 29px;
    }
  }

  @include bp-full {
    font-size: 20px;

    &__input {
      padding: 8px 68px 8px 12px;
    }

    &__icon {
      width: 26px;
      height: 13px;
      margin-top: -7px;
      right: 42px;
    }
  }
}
