/* ==========================================================================
   #AWARDS
   ========================================================================== */

.c-awards {
  &__year {
    & + & {
      margin-top: (65/25)+em;
    }
  }

  @include bp-large {
    padding-left: 30px;
  }
}
