/* ==========================================================================
   #LINKS
   ========================================================================== */

.o-links {
  &-background {
    a {
      @include link-marked-responsive($color-yellow);
      border-bottom: 2px solid transparent;
    }
  }

  &-underline {
    a {
      @include link-underline-responsive($color-yellow);
      border-bottom: 2px solid transparent;
    }
  }

  &-pink {
    a {
      &:hover {
        color: $color-pink;
      }
    }
  }
}
