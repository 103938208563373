@mixin underline($color: $color-yellow, $thickness: 0.25em, $top: 1em) {
  background: linear-gradient(to bottom, $color, $color) left $top no-repeat;
  background-size: 100% $thickness;
}

@mixin link-underline($color: $color-yellow, $thickness: 0.25em, $top: 1em) {
  transition: box-shadow 0.3s, background-size 0.3s;
  @include underline($color, $thickness, $top);
  background-size: 0% $thickness;

  &:hover {
    background-size: 100% $thickness;
  }
}

@mixin link-marked($color: $color-yellow, $thickness: 0.25em, $top: 1em) {
  transition: box-shadow 0.3s, background-size 0.3s;
  background: linear-gradient(to top, $color, $color) left $top no-repeat, linear-gradient(to top, $color, $color) left top no-repeat;
  background-size: 100% $thickness, 0% 1em;

  &:hover {
    background-size: 100% $thickness, 100% 1em;
  }
}

@mixin underline-responsive($color: $color-yellow, $thickenss: $underline-thickness-small, $thickenss-medium: $underline-thickness-medium, $thickenss-large: $underline-thickness-large, $thickenss-full: false, $top: 1em) {
  @include underline($color, $thickenss, $top);

  @include bp-medium {
    @include underline($color, $thickenss-medium, $top);
  }

  @include bp-xlarge {
    @include underline($color, $thickenss-large, $top);
  }

  @if ($thickenss-full != false) {
    @include bp-full {
      @include underline($color, $thickenss-full, $top);
    }
  }
}

@mixin link-underline-responsive($color: $color-yellow, $thickenss: $underline-thickness-small, $thickenss-medium: $underline-thickness-medium, $thickenss-large: $underline-thickness-large, $thickenss-full: false, $top: 1em) {
  @include link-underline($color, $thickenss, $top);

  @include bp-medium {
    @include link-underline($color, $thickenss-medium, $top);
  }

  @include bp-xlarge {
    @include link-underline($color, $thickenss-large, $top);
  }

  @if ($thickenss-full != false) {
    @include bp-full {
      @include link-underline($color, $thickenss-full, $top);
    }
  }
}

@mixin link-marked-responsive($color: $color-yellow, $thickenss: $underline-thickness-small, $thickenss-medium: $underline-thickness-medium, $thickenss-large: $underline-thickness-large, $thickenss-full: false, $top: 1em) {
  @include link-marked($color, $thickenss, $top);

  @include bp-medium {
    @include link-marked($color, $thickenss-medium, $top);
  }

  @include bp-xlarge {
    @include link-marked($color, $thickenss-large, $top);
  }
  @if ($thickenss-full != false) {
    @include bp-full {
      @include link-marked($color, $thickenss-full, $top);
    }
  }
}

@mixin h1 {
  font-size: 20px;

  @include bp-medium {
    font-size: 25px;
  }

  @include bp-large {
    font-size: 35px;
  }
  @include bp-full {
    font-size: 50px;
  }
}

@mixin h1-section-title {
  font-size: 15px;
  margin-bottom: 20px;
  text-transform: uppercase;
  color: $color-pink;
  font-weight: 400;
  font-family: $font-sans-serif;

  @include bp-medium {
    font-size: 20px;
  }

  @include bp-xlarge {
    font-size: 25px;
  }

  @include bp-full {
    font-size: 30px;
  }
}
