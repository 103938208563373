/* ==========================================================================
   #GAP
   ========================================================================== */

.c-gap {
  display: block;
  height: 0;
  width: 100%;

  @each $gap in $gaps {
    &--#{nth($gap, 1)} {
      height: #{nth($gap, 2)}px;
    }
  }

  @include bp-medium {
    @each $gap in $gaps {
      &--#{nth($gap, 1)} {
        height: #{nth($gap, 3)}px;
      }
    }
  }

  @include bp-large {
    @each $gap in $gaps {
      &--#{nth($gap, 1)} {
        height: #{nth($gap, 4)}px;
      }
    }
  }

  @include bp-xlarge {
    @each $gap in $gaps {
      &--#{nth($gap, 1)} {
        height: #{nth($gap, 5)}px;
      }
    }
  }

  @include bp-full {
    @each $gap in $gaps {
      &--#{nth($gap, 1)} {
        height: #{nth($gap, 6)}px;
      }
    }
  }
}
