/* ==========================================================================
   #FONT
   ========================================================================== */

.u-media {
  &-fit-cover {
    object-fit: cover;
  }

  &-fit-contain {
    object-fit: contain;
  }
}
