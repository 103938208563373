/* ==========================================================================
   #TEXT
   ========================================================================== */

.u-text {
  &-uppercase {
    text-transform: uppercase;
  }

  &-center {
    text-align: center;
  }

  &-left {
    text-align: left;
  }

  &-right {
    text-align: right;
  }

  &-label {
    color: $color-pink;
    text-transform: uppercase;
    font-family: $font-sans-serif;
  }
}
