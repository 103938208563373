///* ========================================================================
//   #BREAKPOINTS
//   ======================================================================== */

@mixin bp-small {
  @media (min-width: $bp-small) {
    @content;
  }
}

@mixin bp-medium {
  @media (min-width: $bp-medium) {
    @content;
  }
}

@mixin bp-large {
  @media (min-width: $bp-large) {
    @content;
  }
}

@mixin bp-xlarge {
  @media (min-width: $bp-xlarge) {
    @content;
  }
}

@mixin bp-full {
  @media (min-width: $bp-full) {
    @content;
  }
}

@mixin bp-xfull {
  @media (min-width: $bp-xfull) {
    @content;
  }
}

@mixin bp-custom($width) {
  @media (min-width: $width) {
    @content;
  }
}

@mixin bp-max-small {
  @media (max-width: ($bp-small - 1px)) {
    @content;
  }
}

@mixin bp-max-medium {
  @media (max-width: ($bp-medium - 1px)) {
    @content;
  }
}

@mixin bp-max-large {
  @media (max-width: ($bp-large - 1px)) {
    @content;
  }
}

@mixin bp-max-xlarge {
  @media (max-width: ($bp-xlarge - 1px)) {
    @content;
  }
}

@mixin bp-max-full {
  @media (max-width: ($bp-full - 1px)) {
    @content;
  }
}

@mixin bp-max-xfull {
  @media (max-width: ($bp-xfull - 1px)) {
    @content;
  }
}

@mixin bp-max-custom($width) {
  @media (max-width: ($width - 1px)) {
    @content;
  }
}
