/* ==========================================================================
   #MAIN MENU
   ========================================================================== */

.c-main-menu {
  list-style-type: none;
  padding: 0;
  margin: 0;
  font-size: 15px;
  line-height: 1.6666;

  li {
    margin: 0;
  }

  & > li > a {
    text-transform: uppercase;
    font-size: 25px;
    font-weight: 700;
  }

  & > li {
    margin-bottom: 31px;
  }

  ul, &__text {
    margin: 0 0 0 20px;
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin-top: 2px;
  }
}
