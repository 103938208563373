/* ==========================================================================
   #HEADINGS
   ========================================================================== */

.o-h1 {
  @include h1;
}

.o-h1-section {
  @include h1-section-title;
}
