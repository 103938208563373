/* ==========================================================================
   #CONTAINER
   ========================================================================== */

.o-container {
  margin: 0 auto;
  width: calc(100% - 80px);
  max-width: 1600px;

  &--wide {
    max-width: none;
  }

  & & {
    max-width: none;
    width: auto;
  }

  @include bp-medium {
    width: calc(100% - 120px);

    &--wide {
      width: calc(100% - 80px);
    }
  }

  @include bp-large {
    width: calc(100% - 160px);

    &--wide {
      width: calc(100% - 80px);
    }
  }

  @include bp-xlarge {
    &--wide {
      width: calc(100% - 80px);
    }
  }

  @include bp-full {
    &--wide {
      width: calc(100vw - 100px);
    }
  }

  @include bp-custom($bp-container-xfull) {
    max-width: 1920px;
  }
}
