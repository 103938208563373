/* ==========================================================================
   #VIDEO
   ========================================================================== */

.c-video {
  position: relative;
  overflow: hidden;

  a {
    background: none;
  }

  &__video-wrapper {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 100%;
    padding-top: 240%;
    transform: translateY(-50%);
  }

  &__video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: opacity 0.2s;
    pointer-events: none;
  }

  &.faded-out &__video {
    opacity: 0;
  }

  &.no-transition &__video {
    transition: none;
  }

  &__controls {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 40px;
    display: flex;
    background-color: rgba($color-pink, 0.9);
    align-items: center;
    padding: 0 15px;
    transition: opacity 0.3s, visibility 0.3s;
    opacity: 0;
    visibility: hidden;
  }

  &:hover &__controls {
    opacity: 1;
    visibility: visible;
  }

  &__icon {
    fill: #fff;
    height: 20px;
    display: block;
  }

  &__button {
    opacity: 0.9;
    transition: opacity 0.3s;
    display: block;
    padding: 10px;
    flex-shrink: 0;

    &:hover {
      opacity: 1;
    }
  }

  &__progress {
    flex-grow: 1;
    flex-shrink: 1;
    margin: 0 10px;
    box-sizing: content-box;
    height: 5px;
    border-radius: 0;
    overflow: hidden;
    background-color: rgba(#fff, 0.8);
    -webkit-appearance: none; /* stylelint-disable-line */
    -moz-appearance: none; /* stylelint-disable-line */
    appearance: none; /* stylelint-disable-line */
    border: none;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    background-clip: content-box;

    &::-webkit-progress-bar {
      background: transparent;
    }

    &::-webkit-progress-value {
      background-color: rgba($color-grey, 0.95);
    }

    &::-moz-progress-bar {
      background-color: rgba($color-grey, 0.95);
    }

    &::-ms-fill {
      background-color: rgba($color-grey, 0.95);
    }
  }

  &__mute.muted &__mute-waves {
    display: none;
  }
}
