/* ==========================================================================
   #POSTS LIST
   ========================================================================== */

.c-posts-list {
  &__item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__image {
    display: block;
    width: 96px;
    height: 96px;
  }

  &__thumbnail {
    display: block;
    flex-shrink: 0;
    align-self: flex-start;
    margin-right: 10px;
  }

  &__title {
    line-height: 1.1;
    margin-bottom: 0;
    font-size: 20px;
  }

  &__excerpt {
    display: none;
  }

  &__date, &__author, &__excerpt {
    font-size: 10px;
    line-height: 1.4;
  }

  &__date {
    display: block;
  }

  &__more {
    width: 40px;
    height: 30px;
    margin: 18px auto 0;
    display: block;
    color: $color-pink;

    svg {
      height: 26px;
      width: 14px;
      display: block;
      margin: auto;
      transform: rotate(-90deg);
    }
  }

  @include bp-medium {
    &__image {
      width: 140px;
      height: 140px;
    }

    &__thumbnail {
      margin-right: 12px;
    }

    &__item {
      margin-bottom: 30px;
    }

    &__excerpt {
      display: block;
      line-height: 1.5;
      margin-top: 18px;
    }

    &__title, &__excerpt {
      width: 100%;
    }

    &__text {
      display: flex;
      flex-wrap: wrap;
    }

    &__date, &__author {
      margin-top: 5px;
    }

    &__date {
      margin-right: 60px;
    }

    &__more {
      margin-top: 30px;
    }
  }

  @include bp-large {
    &__image {
      width: 186px;
      height: 186px;
    }

    &__thumbnail {
      margin-right: 16px;
    }

    &__title {
      font-size: 25px;
    }

    &__date, &__author, &__excerpt {
      font-size: 15px;
    }

    &__excerpt {
      margin-top: 20px;
    }

    &__item {
      margin-bottom: 35px;
    }
  }

  @include bp-xlarge {
    &__image {
      width: 233px;
      height: 233px;
    }

    &__thumbnail {
      margin-right: 20px;
    }

    &__title {
      font-size: 35px;
      line-height: (36/35);
    }

    &__date, &__author, &__excerpt {
      font-size: 15px;
    }

    &__date {
      margin-right: 70px;
    }

    &__date, &__author {
      margin-top: 10px;
    }

    &__excerpt {
      margin-top: 32px;
    }

    &__item {
      margin-bottom: 50px;
    }
  }

  @include bp-full {
    &__image {
      width: 350px;
      height: 350px;
    }

    &__thumbnail {
      margin-right: 30px;
    }

    &__title {
      font-size: 50px;
      line-height: (55/50);
    }

    &__date, &__author, &__excerpt {
      font-size: 25px;
      line-height: (35/25);
    }

    &__date {
      margin-right: 70px;
    }

    &__date, &__author {
      margin-top: 15px;
    }

    &__excerpt {
      margin-top: 50px;
    }

    &__item {
      margin-bottom: 70px;
    }
  }
}
