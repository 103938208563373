/* ==========================================================================
   #THUMBNAILS
   ========================================================================== */

.c-thumbnails {
  padding: 30px 0;
  border-top: 1px solid $color-light-grey;
  border-bottom: 1px solid $color-light-grey;

  &__items {
    margin-bottom: -20px;
  }

  &__item {
    display: block;
    margin-bottom: 20px;
    text-align: left;
    width: 100%;
  }

  &__caption {
    display: block;
    font-size: 10px;
    padding: 0 20px;
    margin-top: 10px;
  }

  &__image {
    display: block;
    max-width: 320px;
    width: 100%;
    height: auto;
  }

  @include bp-max-medium {
    &__item {
      cursor: default;
      outline: none;
    }
  }

  @include bp-medium {
    padding: 20px 0;

    &__items {
      display: flex;
      flex-wrap: wrap;
      margin-left: -50px;
    }

    &__item {
      width: auto;
      margin-left: 50px;
    }

    &__image {
      height: 90px;
      width: auto;
      max-width: none;
    }

    &__caption {
      padding: 0 10px;
    }
  }

  @include bp-large {
    &__image {
      height: 80px;
    }

    &__items {
      margin-left: -45px;
      margin-bottom: -15px;
    }

    &__item {
      margin-left: 45px;
      margin-bottom: 15px;
    }
  }

  @include bp-xlarge {
    padding: 30px 0;

    &__image {
      height: 100px;
    }

    &__items {
      margin-left: -60px;
      margin-bottom: -20px;
    }

    &__item {
      margin-left: 60px;
      margin-bottom: 20px;
    }
  }

  @include bp-full {
    padding: 40px 0;

    &__image {
      height: 145px;
    }

    &__items {
      margin-left: -80px;
      margin-bottom: -30px;
    }

    &__item {
      margin-left: 80px;
      margin-bottom: 30px;
    }

    &__caption {
      font-size: 15px;
      margin-top: 15px;
    }
  }
}
