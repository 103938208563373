/* ==========================================================================
   #HERO
   ========================================================================== */

.c-hero {
  position: relative;

  &__image {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
