/* ==========================================================================
   #STATS
   ========================================================================== */

.c-stats {
  display: flex;
  align-items: center;

  &--bottom, &--top {
    flex-wrap: wrap;
  }

  &__stat {
    font-size: 55px;
    line-height: 1;
  }

  &--left &__stat {
    flex-shrink: 0;
  }

  &--top &__stat {
    order: 1;
  }

  &__label {
    font-size: 10px;
    margin-left: 15px;
    line-height: 1.5;
  }

  &--bottom &__label,
  &--top &__label {
    margin-left: 0;
    width: 100%;
  }

  &--top &__label {
    order: 0;
  }

  &--small &__stat {
    font-size: 25px;
  }

  @include bp-medium {
    &__stat {
      font-size: 45px;
    }

    &__label {
      margin-left: 10px;
    }
  }

  @include bp-large {
    &__stat {
      font-size: 55px;
    }

    &__label {
      margin-left: 15px;
    }
  }

  @include bp-xlarge {
    &__stat {
      font-size: 75px;
    }

    &--small &__stat {
      font-size: 35px;
    }

    &__label {
      margin-left: 20px;
      font-size: 15px;
    }
  }

  @include bp-full {
    &__stat {
      font-size: 85px;
    }

    &--small &__stat {
      font-size: 40px;
    }

    &__label {
      font-size: 18px;
    }
  }

  @include bp-custom($bp-container-full) {
    &__stat {
      font-size: 105px;
    }

    &--small &__stat {
      font-size: 50px;
    }

    &__label {
      margin-left: 35px;
      font-size: 20px;
    }
  }
}
