/* ==========================================================================
   #CONTAINER INSET
   ========================================================================== */

.o-container-inset {
  @include bp-medium {
    padding-right: 60px;
  }

  @include bp-large {
    @at-root .o-columns__column--wide:first-child & {
      padding-left: 40px;
      padding-right: 50px;
    }

    @at-root .o-columns__column--wide:nth-child(even) & {
      padding-left: 30px;
      padding-right: 55px;
    }
  }

  @include bp-xlarge {
    @at-root .o-columns__column--wide:first-child & {
      padding-left: 40px;
      padding-right: 0;
    }

    @at-root .o-columns__column--wide:nth-child(even) & {
      padding-left: 20px;
      padding-right: 100px;
    }
  }

  @include bp-full {
    @at-root .o-columns__column--wide:first-child & {
      padding-left: 80px;
      padding-right: 80px;
    }

    @at-root .o-columns__column--wide:nth-child(even) & {
      padding-left: 80px;
      padding-right: 80px;
    }
  }
}
