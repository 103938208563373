/* ==========================================================================
   #COLUMNS
   ========================================================================== */

.c-columns {
  line-height: (35/25);
  margin-bottom: -1em;

  &--preserve-margin {
    margin-bottom: 0;
  }

  p {
    break-inside: avoid;
    display: inline-block;
    width: 100%;
  }

  @include bp-medium {
    columns: 2;
    column-gap: 80px;
  }

  @include bp-large {
    columns: 3;
    column-gap: 80px;
  }
}
