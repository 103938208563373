/* ==========================================================================
   #COMMENTS FORM
   ========================================================================== */

.c-comment-form {
  font-size: 11px;
  text-align: right;
  max-width: 480px;

  &__submit {
    font-size: 8px;
    color: #fff;
    background-color: $color-grey;
    width: 60px;
    height: 20px;
    line-height: 1;
    text-align: center;

    &:hover {
      background-color: $color-pink;
    }
  }

  &__label {
    display: block;
  }

  &__input {
    border: 1px solid $color-light-grey;
    padding: 0.5em 0.75em;
    width: 100%;
    display: block;
  }

  &__row {
    margin-bottom: 15px;
    text-align: left;
  }

  @include bp-medium {
    max-width: none;
    font-size: 15px;

    &__submit {
      font-size: 11px;
      width: 80px;
      height: 27px;
    }

    &__row {
      margin-bottom: 20px;
    }

    textarea {
      height: 150px;
    }
  }

  @include bp-xlarge {
    &__row {
      margin-bottom: 25px;
    }

    textarea {
      height: 190px;
    }

    &__submit {
      font-size: 13px;
      width: 100px;
      height: 33px;
    }
  }

  @include bp-full {
    font-size: 25px;

    &__row {
      margin-bottom: 35px;
    }

    textarea {
      height: 290px;
    }

    &__submit {
      font-size: 20px;
      width: 150px;
      height: 50px;
    }
  }
}
