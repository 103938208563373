/* ==========================================================================
   #EMBED
   ========================================================================== */

.c-embed--video {
  position: relative;

  .wpb_video_wrapper & {
    height: 0;
  }
}

.c-embed--video:before {
  display: block;
  content: '';
  padding-top: 9/16 * 100%;
}

.c-embed--video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
