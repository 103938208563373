@function tile-gap($breakpoint: 0) {
  $gap: 5;

  @if $breakpoint >= $bp-large {
    $gap: 7;
  }

  @return $gap;
}

@mixin tile-height($tile-width, $tile-height, $tile-gap, $columns, $cell-span: 1) {
  $scale: $tile-height / $tile-width;
  $calculated-tile-height: '(#{1 / $columns * 100%} - #{$tile-gap + px}) * #{$scale * $cell-span}';
  @if ($cell-span > 1) {
    $calculated-tile-height: '#{$calculated-tile-height} + #{($cell-span - 1) * $tile-gap}px';
  }
  padding-top: calc(#{$calculated-tile-height});
}

@mixin tile-width($tile-gap, $columns, $row-span: 1) {
  @if ($tile-gap > 0) {
    width: calc(#{$row-span / $columns * 100%} - #{$tile-gap + px});
  }
  @else {
    width: calc(#{$row-span / $columns * 100%});
  }
}

@mixin tile-size($tile-width, $tile-height, $tile-gap, $columns, $row-span: 1, $cell-span: 1) {
  @include tile-height($tile-width, $tile-height, $tile-gap, $columns, $cell-span);
  @include tile-width($tile-gap, $columns, $row-span);
}

@mixin tile-sizes($tile-width, $tile-height, $tile-gap, $columns) {
  @at-root &--sizer {
    @include tile-width(0, $columns, 1);
    @include tile-height($tile-width, $tile-height, $tile-gap, $columns, 1);
    padding-bottom: $tile-gap + px;
  }

  &--1x1 {
    @include tile-size($tile-width, $tile-height, $tile-gap, $columns, 1, 1);
  }

  &--1x2 {
    @include tile-size($tile-width, $tile-height, $tile-gap, $columns, 1, 2);
  }

  &--1x3 {
    @include tile-size($tile-width, $tile-height, $tile-gap, $columns, 1, 3);
  }

  &--2x1 {
    @include tile-size($tile-width, $tile-height, $tile-gap, $columns, 2, 1);
  }

  &--2x2 {
    @include tile-size($tile-width, $tile-height, $tile-gap, $columns, 2, 2);
  }

  &--2x3 {
    @include tile-size($tile-width, $tile-height, $tile-gap, $columns, 2, 3);
  }
}

@mixin tile-video-size($tile-width, $tile-height) {
  $scale: ($tile-height / $tile-width) / (9/16) * 100%;
  width: $scale;
  margin-left: -(($scale - 100%) / 2);
}
