/* ==========================================================================
   #ACCORDION
   ========================================================================== */

.c-accordion {
  border-top: 1px solid $color-light-grey;
  border-bottom: 1px solid $color-light-grey;

  & + & {
    border-top-color: transparent;
  }

  &__header {
    margin: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 7px 9px 7px;
    line-height: 1.4;

    &[role='button'] {
      cursor: pointer;
    }
  }

  &__icon {
    width: 14px;
    height: 8px;
    display: block;
    transition: transform 0.3s;
    margin-left: 13px;
    margin-right: 6px;

    @media print {
      display: none;
    }
  }

  &.active &__icon {
    transform: rotate(180deg);
  }

  &__content {
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.4s;

    &-inner {
      margin: 0 7px;
      padding-bottom: 10px;
    }

    @media print {
      max-height: none;
    }
  }

  &.active &__content {
    max-height: none;
  }

  &__content--inset &__content-inner {
    padding-left: 5px;
  }

  @include bp-large {
    &__content {
      &-inner {
        padding-bottom: 15px;
      }
    }

    &__content--inset &__content-inner {
      padding-left: 7px;
    }
  }

  @include bp-xlarge {
    &__header {
      padding-top: 13px;
      padding-bottom: 11px;
    }

    &__icon {
      width: 19px;
      height: 10px;
    }

    &__content {
      &-inner {
        padding-bottom: 20px;
      }
    }

    &__content--inset &__content-inner {
      padding-left: 10px;
    }
  }

  @include bp-full {
    &__header {
      padding: 19px 11px 16px 11px;
    }

    &__icon {
      width: 26px;
      height: 13px;
      margin-right: 13px;
    }

    &__content {
      &-inner {
        margin: 0 11px;
        padding-bottom: 30px;
      }
    }
  }
}
