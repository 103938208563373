/* ==========================================================================
   #DATE TABLE
   ========================================================================== */

.c-date-table {
  display: table;
  line-height: (35/20);
  width: 100%;
  border-collapse: collapse;

  &__text {
    padding-bottom: 1em;
    display: table-cell;
  }

  &__row {
    display: table-row;
  }

  &__row:last-child &__text {
    padding-bottom: 0;
  }

  &__date {
    white-space: nowrap;
    padding-right: 30px;
    display: table-cell;
  }

  @include bp-large {
    &__date {
      padding-right: 40px;
    }
  }

  @include bp-xlarge {
    &__date {
      padding-right: 50px;
    }
  }

  @include bp-xlarge {
    &__date {
      padding-right: 55px;
    }
  }
}
