/* ==========================================================================
   #POST
   Includes styles for default WP images classes
   ========================================================================== */

.alignnone {
  margin: 0;
}

.aligncenter {
  margin: 0 auto;
}

.alignright {
  float: right;
  margin: 0;
}

.alignleft {
  float: left;
  margin: 1em 1em 1em 0;
}

.alignnone, .aligncenter, .alignright, .alignleft {
  width: auto;
  height: auto;
}

.wp-caption {
  text-align: left;
}

.wp-caption-text {
  font-style: normal;
  margin-top: 0.5em;
}
