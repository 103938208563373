/* ==========================================================================
   #FONT
   ========================================================================== */

.u-font {
  &-regular {
    font-weight: 400;
  }

  &-medium {
    font-weight: 400;
  }

  &-bold {
    font-weight: 700;
  }

  &-extra-bold {
    font-weight: 800;
  }

  &-italic {
    font-style: italic;
  }

  &-normal {
    font-style: normal;
  }

  &-serif {
    font-family: $font-serif;
  }

  &-sans-serif {
    font-family: $font-sans-serif;
  }

  &-style-normal {
    font-style: normal;
  }
}
