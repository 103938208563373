/* ==========================================================================
   #SOCIAL TILES
   ========================================================================== */

.c-social-tiles {
  color: #fff;
  display: none;
  align-items: stretch;
  justify-content: flex-start;

  &:before {
    content: '';
    display: block;
    padding-top: (247/200)*100%;
    width: 0;
  }

  &__column {
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    flex-wrap: wrap;
    flex-grow: 1;
  }

  &__post {
    background-color: $color-grey;
    font-size: 12px;
    line-height: 15px;
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    flex-grow: 1;
    align-items: stretch;

    &--instagram {
      background-color: $color-pink;
    }

    &-inner {
      display: flex;
      flex-wrap: wrap;
      padding: 22px 21px 14px 24px;
      flex-grow: 1;
      width: 100%;
    }
  }

  &__text {
    height: #{12 * 15}px;
    overflow: hidden;
    word-break: break-word;

    a:not(.twitter-hashtag):not(.twitter-atreply) {
      @include link-marked-responsive($color-yellow, $underline-thickness-small, $underline-thickness-medium, 5px, false, 0.9em);
    }

    .twitter-hashtag, .twitter-atreply {
      @include link-underline-responsive($color-yellow, $underline-thickness-small, $underline-thickness-medium, 5px, false, 0.9em);
    }

    &.clamped {
      position: relative;

      &:after {
        text-align: right;
        content: '[...]';
        display: block;
        position: absolute;
        right: -1em;
        left: 50%;
        bottom: 0;
        padding-right: 1em;
        background: linear-gradient(to right, rgba($color-grey, 0) 0, $color-grey 50%, $color-grey 100%);
      }
    }
  }

  &__post--small &__text {
    height: #{4 * 15}px;
  }

  &__post--instagram &__text.clamped:after {
    background: linear-gradient(to right, rgba($color-pink, 0) 0, $color-pink 50%, $color-pink 100%);
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    font-size: 9px;
    align-items: center;
    width: 100%;
    flex-shrink: 0;
    flex-grow: 0;
    align-self: flex-end;
  }

  &__date {
    display: block;
  }

  &__icon {
    display: block;

    svg {
      display: block;
    }

    &:hover svg {
      fill: $color-yellow;
    }
  }

  &__handle {
    @include link-underline-responsive($color-yellow, $underline-thickness-small, $underline-thickness-medium, 5px, false, 0.9em);
  }

  &__info {
    line-height: 1.5;
    padding-right: 3px;
  }

  &__image-wrapper {
    display: none;
  }

  @include bp-max-large {
    &__post--instagram,
    &__post--small {
      display: none;
    }
  }

  @include bp-medium {
    display: flex;

    &__text a, &__handle {
      border-bottom: 1px solid transparent;
    }
  }

  @include bp-large {
    &:before {
      padding-top: (343/260)*100%;
    }

    &__column {
      width: calc(50% - 1px);
      flex-direction: column;

      &:last-child {
        margin-left: 2px;
      }
    }

    &__post {
      font-size: 10px;
      line-height: 13px;

      &--instagram {
        position: relative;
      }

      &--instagram:before {
        display: block;
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 10px 10px 0 10px;
        border-color: #fff transparent transparent;
        position: absolute;
        top: 0;
        right: 10px;
      }

      &--small {
        margin-top: 2px;
        display: flex;
        flex-wrap: nowrap;
        flex-grow: 0;
      }

      &--small:before {
        display: block;
        content: '';
        padding-top: 100%;
        width: 0;
      }

      &-inner {
        padding: 16px 12px 13px 12px;
      }
    }

    &__image-wrapper {
      display: block;
      flex-grow: 0;
      flex-shrink: 0;
      width: 100%;
      margin-bottom: 2px;
    }

    &__image {
      display: block;
      width: 100%;
      height: auto;
    }

    &__info {
      font-size: 8px;
    }

    &__icon svg {
      width: 12px;
      height: 12px;
    }

    &__text {
      height: #{12 * 13 + 2}px;
    }

    &__text a, &__handle {
      border-bottom-width: 2px;
    }

    &__post--small &__text {
      height: #{4 * 13 + 2}px;
    }
  }

  @include bp-xlarge {
    &:before {
      padding-top: (413.703/315.703)*100%;
    }

    &__post {
      font-size: 12px;
      line-height: 15px;

      &-inner {
        padding: 24px 18px 16px;
      }

      &--instagram:before {
        border-width: 13px 13px 0 13px;
        right: 12px;
      }
    }

    &__info {
      font-size: 9px;
    }

    &__icon svg {
      width: 15px;
      height: 15px;
    }

    &__text {
      height: #{12 * 15 + 1}px;
    }

    &__text a, &__handle {
      border-bottom-width: 4px;
    }

    &__post--small &__text {
      height: #{4 * 15 + 1}px;
    }
  }

  @include bp-full {
    &:before {
      padding-top: (503.75/368.125)*100%;
    }

    &__column {
      width: calc(50% - 2px);

      &:last-child {
        margin-left: 4px;
      }
    }

    &__image-wrapper {
      margin-bottom: 4px;
    }

    &__post {
      font-size: 14px;
      line-height: 18px;

      &-inner {
        padding: 30px 20px 20px;
      }

      &--instagram:before {
        border-width: 20px 19px 0 19px;
        right: 15px;
      }

      &--small {
        margin-top: 4px;
      }
    }

    &__info {
      font-size: 11px;
    }

    &__icon svg {
      width: 18px;
      height: 18px;
    }

    &__text {
      height: #{12 * 18 + 3}px;
    }

    &__text a, &__handle {
      border-bottom-width: 4px;
    }

    &__post--small &__text {
      height: #{4 * 18 + 3}px;
    }
  }

  @include bp-custom($bp-container-full) {
    &:before {
      padding-top: 650px;
    }

    &__post {
      font-size: 20px;
      line-height: 25px;

      &-inner {
        padding: 32px 20px 22px 26px;
      }
    }

    &__info {
      font-size: 15px;
    }

    &__footer {
      padding-right: 6px;
    }

    &__text {
      height: #{12 * 25}px;
      letter-spacing: -0.03em;
    }

    &__text a, &__handle {
      border-bottom-width: 1px;
    }

    &__post--small &__text {
      height: #{4 * 25}px;
    }

    &__icon svg {
      width: 22px;
      height: 19px;
    }
  }

  @include bp-custom($bp-container-xfull) {
    &:before {
      padding-top: 780px;
    }
  }
}
