/* ==========================================================================
   #TILES
   ========================================================================== */

.c-tiles {
  $tile-gap: tile-gap();
  margin-bottom: -#{$tile-gap}px;

  &__items {
    display: flex;
    flex-wrap: wrap;
    margin-left: -#{tile-gap() + px};
    align-items: flex-start;
    position: relative;
  }

  .c-tile {
    margin-left: $tile-gap + px;
    margin-bottom: $tile-gap + px;
  }

  &__title {
    margin-bottom: 20px;
  }

  &__sizer {
    position: absolute;
    top: 0;
    left: 0;
  }

  @include bp-large {
    $tile-gap: tile-gap($bp-large);
    margin-bottom: -#{$tile-gap}px;

    &__items {
      margin-left: -#{tile-gap($bp-large) + px};
    }

    .c-tile {
      margin-left: $tile-gap + px;
      margin-bottom: $tile-gap + px;
    }
  }

  @include bp-xlarge {
    &__title {
      margin-bottom: 30px;
    }
  }
}
