/* ==========================================================================
   #HEADER
   ========================================================================== */

.c-header {
  color: $color-white;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;

  /*&:before {
    display: block;
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color-pink, 0.85);
    z-index: 1;
  }*/

  &--transparent:before {
    background-color: transparent;
  }

  &__inner {
    position: relative;
    z-index: 100;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 100%;
    top: 0;
    left: 0;
    transition: transform 0.4s;
    margin-bottom: auto;
  }

  &.fixed &__inner {
    position: fixed;
    will-change: transform;
    overflow: hidden;
  }

  &.fast-anim.hidden &__inner {
    transition: transform 0.2s;
  }

  &.fixed.hidden &__inner {
    transform: translateY(-100%);
  }

  &.no-animation-bottom &__bottom,
  &.no-animation &__inner {
    transition: none;
  }

  &--transparent {
    z-index: 100;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }

  &__top {
    position: relative;
    width: 100%;
    padding: 20px 0 10px;
    background-color: rgba($color-pink, 0.85);

    &:last-child {
      padding-bottom: 20px;
    }

    &-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
    }
  }

  &--transparent:not(.fixed) &__top {
    background-color: transparent;
  }

  &__bottom {
    position: relative;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 5px 0 10px;
    transition: transform 0.4s;
    overflow: hidden;

    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba($color-pink, 0.85);
    }

    &-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      position: relative;
    }

    &-background {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: none;
    }
  }

  &.fixed-bottom &__bottom {
    position: fixed;
    z-index: 100;
  }

  &.fixed-bottom &__bottom-background {
    display: block;
    z-index: -1;
  }

  &.fixed-bottom.fixed:not(.hidden) &__bottom {
    transform: translateY(-100%);
  }

  &.fixed-bottom &__bottom {
    will-change: transform;
    top: 0;
    left: 0;
    bottom: auto;
  }

  &__submenu {
    list-style-type: none;
    margin-bottom: 0;
    margin-left: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    font-size: 15px;
    line-height: 1;

    li {
      margin-right: 20px;
      margin-bottom: 0;

      &:last-child {
        margin-right: 0;
      }
    }

    a {
      @include link-underline-responsive($color-yellow);
      border: solid transparent;
      border-top-width: 2px;
      border-bottom-width: 2px;
    }

    .active a,
    .current-menu-item a {
      @include underline-responsive($color-yellow);
    }
  }

  &__headline:not(:last-child) &__subtitle {
    margin-bottom: 10px;
  }

  &__logo {
    display: block;

    &-image {
      display: block;
      width: 60px;
      height: 20px;
    }
  }

  &__title {
    margin-bottom: 0;
    line-height: 1.2;

    a {
      @include link-underline-responsive($color-yellow, $underline-thickness-small, $underline-thickness-medium, $underline-thickness-large, false, 0.95em);
    }
  }

  &__background,
  &__inner-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__inner-background {
    display: none;
  }

  &.fixed &__inner-background {
    display: block;
  }

  &__navigation {
    z-index: 101;
  }

  &__subtitle {
    font-size: 15px;
    line-height: (20/15);
    font-style: italic;
    margin-top: 8px;
    margin-bottom: 0;
  }

  &__search {
    margin-top: 10px;
  }

  @include bp-medium {
    display: block;

    &:before {
      display: none;
    }

    &:not(&--transparent) {
      height: (600/1920) * 100vw;
      max-height: 800px;
    }

    &__logo {
      &-image {
        width: 80px;
        height: 25px;
      }
    }

    &__top {
      padding-bottom: 15px;

      &:last-child {
        padding-bottom: 20px;
      }
    }

    &:not(.fixed) &__top {
      background-color: transparent;
    }

    &__headline:not(:last-child) .c-header__subtitle {
      margin-bottom: 0;
    }

    &__inner {
      &-background {
        height: (600/1920) * 100vw;
        max-height: 800px;
      }
    }

    &__bottom {
      position: absolute;
      padding-top: 10px;

      &-container {
        flex-wrap: nowrap;
      }

      &-background {
        height: (600/1920) * 100vw;
        max-height: 800px;
      }
    }

    &__submenu {
      flex-wrap: nowrap;

      li {
        margin-left: 20px;
      }
    }

    &__subtitle {
      margin-top: 5px;
    }
  }

  @include bp-large {
    &__logo {
      &-image {
        width: 100px;
        height: 32px;
      }
    }

    &__top {
      padding: 30px 0 15px;

      &:last-child {
        padding-bottom: 30px;
      }
    }

    &__submenu {
      li {
        margin-left: 40px;
      }
    }
  }

  @include bp-xlarge {
    &__top {
      padding: 35px 0 20px;

      &:last-child {
        padding-bottom: 35px;
      }
    }

    &__submenu, &__subtitle {
      font-size: 20px;
    }

    &__submenu {
      li {
        margin-left: 50px;
      }
    }
  }

  @include bp-full {
    &__logo {
      &-image {
        width: 152px;
        height: 48px;
      }
    }

    &:not(.fixed) &__top:last-child {
      padding: 50px 0;
    }

    &__bottom {
      padding: 20px 0;
    }

    &__submenu, &__subtitle {
      font-size: 30px;
    }

    &__submenu {
      li {
        margin-left: 80px;
      }
    }
  }

  @include bp-xfull {
    &__logo {
      &-image {
        width: 182px;
        height: 58px;
      }
    }
  }
}
