/* ==========================================================================
   #MAP
   ========================================================================== */

.c-map {
  display: flex;

  &:before {
    content: '';
    display: block;
    padding-top: (3/4)*100%;
  }

  &__container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
