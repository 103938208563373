/* ==========================================================================
   #HR
   ========================================================================== */

p {
  margin-top: 0;
  margin-bottom: 1em;

  &:empty {
    display: none;
  }
}
