/* ==========================================================================
   #WIDGET
   ========================================================================== */

.c-widget {
  border-top: 1px solid $color-light-grey;
  border-bottom: 1px solid $color-light-grey;
  padding: 15px;

  & + & {
    border-top-color: transparent;
  }

  &__title {
    font-size: 15px;
    line-height: 1.2;
    margin-bottom: 10px;
    margin-top: -5px;
  }

  @include bp-medium {
    &__title {
      font-size: 10px;
    }
  }

  @include bp-large {
    &__title {
      font-size: 15px;
    }
  }

  @include bp-xlarge {
    padding: 20px;

    &__title {
      font-size: 20px;
      margin-top: -10px;
    }
  }

  @include bp-full {
    &__title {
      font-size: 25px;
      margin-bottom: 12px;
    }
  }
}
